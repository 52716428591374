import { useEffect } from 'react'
import Layout from '../Components/Layout'
// import portfolio from '.././img/portfolio.jpg'
// import Tabs from '../Components/Tabs/Tabs'
// import PageHeader from '../Components/UI/PageHeader/PageHeader'
import { useTranslation } from "react-i18next"
import Portfolios from '../Components/PortfoliosHome/Portfolios'

const PortfolioPage = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = t('portfolios_page')
  }, [t]);
  return (
    <Layout>
      {/* <PageHeader image={portfolio} title={t('Portfolio')} /> */}

      <section id="Portfolio" className='md:pt-80 PortfolioPage'>
        <div className="container mx-auto my-24">
          {/* <div className="outlet">
          <Tabs />
          </div> */}
          <Portfolios />
        </div>
      </section>
    </Layout>
  )
}

export default PortfolioPage