// import { Link, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { AiOutlineHome, AiOutlineCloseCircle, AiOutlineFundProjectionScreen, AiOutlineInfoCircle, AiOutlineWhatsApp } from 'react-icons/ai'
import { FiPhoneCall, FiFacebook } from 'react-icons/fi'
import { BsJustifyLeft, BsInstagram } from 'react-icons/bs'

import { FaLaptopCode, FaLinkedinIn } from 'react-icons/fa'
import fav from '../../img/favicon.png'
import logo from '../../img/Logo.webp'
import Lang from './lang/Lang'
import whatsapp from '../../img/whatsapp.png'

// import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next"

import './Header.scss'
import { useState } from 'react'


const MobileNav = () => {
     const [sideBar, setSideBar] = useState(false)
     const { t } = useTranslation()
     // const navigate = useNavigate();
     // const location = useLocation();
     // const path = location.pathname

     return (
          <>
               <nav className='topNav flex lg:hidden fixed top-0 left-0 w-full z-50 h-24'>
                    <div className="container mx-auto">
                         <div className="row flex items-center flex-wrap justify-center  h-full">
                              <div className="w-1/3 text-white ">
                                   {/* <button onClick={() => navigate(-1)} className="arrowButton"> */}
                                   <button onClick={() => setSideBar(true)} className="arrowButton text-5xl">
                                        <BsJustifyLeft />
                                   </button>

                              </div>
                              <div className="w-1/3 text-white text-center">
                                   <Link to="/" >
                                        <img src={fav} alt="" className='w-24 mx-auto ' />
                                   </Link>
                              </div>
                              <div className="w-1/3 text-white ">
                                   <Lang />
                              </div>
                         </div>
                    </div>
               </nav>
               <div className={'sideBar fixed top-0 left-0 w-10/12 h-screen z-50 bg-black  shadow-lg ' + (sideBar ? 'active' : '')}>
                    <div className="flex items-center justify-center flex-wrap h-screen">
                         <button className="flex absolute right-4 top-4 text-white" onClick={() => setSideBar(false)} >
                              <AiOutlineCloseCircle className='mx-8 text-4xl' />
                         </button>
                         <ul className='flex-wrap w-full' onClick={() => setSideBar(false)} >
                              <Link to="/" className="w-full  mb-32 flex items-center  m-4 text-white">
                                   <img src={logo} alt="" className='mx-auto w-48 block' />
                              </Link>
                              <Link to="/" className="w-full flex items-center  m-4 mb-12 text-white">
                                   <AiOutlineHome className='mx-8' />
                                   {t('Home')}
                              </Link>
                              <Link to="/services" className="w-full flex items-center  m-4 mb-12 text-white">
                                   <FaLaptopCode className='mx-8' />
                                   {t('Services')}
                              </Link>
                              <Link to="/portfolios" className="w-full flex items-center  m-4 mb-12 text-white">
                                   <AiOutlineFundProjectionScreen className='mx-8' />
                                   {t('Portfolio')}
                              </Link>
                              <Link to="/about" className="w-full flex items-center  m-4 mb-12 text-white">
                                   <AiOutlineInfoCircle className='mx-8' />
                                   {t('About')}
                              </Link>
                              <Link to="/contact" className="w-full flex items-center  m-4 mb-12 text-white">
                                   <FiPhoneCall className='mx-8' />
                                   {t('Contact')}
                              </Link>
                         </ul>
                         <ul className='flex items-center  absolute bottom-8 w-full text-center mx-auto justify-center' onClick={() => setSideBar('close')} >
                              <a href="https://api.whatsapp.com/send?phone=971529962679" target='_blank' rel='noreferrer' className=" flex items-center  text-white">
                                   <AiOutlineWhatsApp className='mx-8 text-4xl' />
                              </a>

                              <a href='https://www.facebook.com/brainclickads' className=" flex items-center  text-white">
                                   <FiFacebook className='mx-8 text-4xl' />
                              </a>
                              <a href='https://www.linkedin.com/company/brain-click-uae' className=" flex items-center  text-white">
                                   <FaLinkedinIn className='mx-8 text-4xl' />
                              </a>
                              <a href='https://www.instagram.com/brainclicksm/' className=" flex items-center  text-white">
                                   <BsInstagram className='mx-8 text-4xl' />
                              </a>
                         </ul>
                    </div>
               </div>
               {/* <nav className="navigation z-30 flex lg:hidden">  */}
               <a href="https://api.whatsapp.com/send?phone=971529962679" target='_blank' rel='noreferrer' className=" whatsappBtn">
                    <img src={whatsapp} alt="" className='w-28 block mr-0 ml-auto float-right' />
               </a>
               {/* <ul>
                    <li className={"list " + (path === "/Portfolio" ? " active" : "")}  >
                              <Link to="/Portfolio" >
                                        <span className="icon">
                                                  <AiOutlineFundProjectionScreen />
                                        </span>
                              </Link>
                    </li>
                    <li className={"list " + (path === "/Packages" ? " active" : "")}  >
                              <Link to="/Packages" >
                                        <span className="icon">
                                                  <AiOutlineShop />
                                        </span>
                              </Link>
                    </li>
                    <li className={"list first" + (path === "/" ? " active" : "")}  >
                              <Link to="/" >
                                        <span className="icon">
                                                  <AiOutlineHome />
                                        </span>
                              </Link>
                    </li>
                    <li className={"list " + (path === "/Contact" ? " active" : "")}  >
                              <Link to="/Contact" >
                                        <span className="icon">
                                                  <BsChatText />
                                        </span>
                                        <span className='hidden'>{t('contact')}</span>
                              </Link>
                    </li>
                    <li className={"list " + (path === "/Services" ? " active" : "")}  >
                              <Link to="/Services" >
                                        <span className="icon">
                                                  <MdOutlineMedicalServices />
                                        </span>
                              </Link>
                    </li>
                    <div className="indicator" ></div>
               </ul> */}
               {/* </nav> */}
          </>
     )
}

export default MobileNav