import { useState } from 'react'
import { t } from 'i18next'
import './Form.scss'
import axios from 'axios'
import { config, url } from '../../Components/Utils/Common'
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
const Form = () => {
  const [loading, setLoading] = useState(false);
  const name = useFormInput('');
  const email = useFormInput('');
  const message = useFormInput('');
  const phone = useFormInput('');
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const hideError = () => {
    setError(null)
  }
  const handleSend = (event) => {
    setError(null);
    event.preventDefault()
    let data = new FormData();
    // const form = {
    //   name: name.value,
    //   email: email.value,
    //   message: message.value,
    //   phone : phone.value
    // }
    
    data.append('name', name.value);
    data.append('email', email.value);
    data.append('message', message.value);
    data.append('phone', phone.value);
    
    axios.post(url + 'form/submit', data, config).then(response => {
      console.log(response);
      // localStorage.setItem('form', JSON.stringify(form))
      navigate("/thanks")
    }).catch(error => {
      setLoading(false);
      console.log(error.response.data.message)
      if (error.response.status === 401) setError(error.response.data.message);
      else setError(error.response.data.message);
    })
  }
 
  return (

    <div className="md:p-6 md:px-0 relative overflow-hidden">
      {error && <div className="relative my-4 bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
        <div className="flex text-right">
          <div className="py-1"><svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
          <div className='mr-4'>
            <p className="font-bold"> {t('error')} </p>
            <p className="text-4xl">{error}</p>
          </div>
        </div>
        <span className='left-4 top-4 absolute text-red-900 text-2xl block cursor-pointer' onClick={hideError}>  <AiOutlineCloseCircle className='font-bold' /> </span>
      </div>}

      <form action="">
          <div className="w-full mt-4">
            <input placeholder={t('fullName')} className="w-full  p-2 px-4 mt-4" type="text" />
          </div>
          <div className="items-center md:flex gap-4 md:mt-4">
            <div className="w-full flex items-start justify-center flex-col">
              <input  {...name} placeholder={t('companyName')} type="text" className="w-full  p-2 px-4 mt-8 md:mt-4" />
            </div>

            <div className="w-full flex items-start justify-center flex-col">
              <input  {...email} placeholder={t('email')} className="w-full  p-2 px-4 mt-8 md:mt-4" type="email" />
            </div>
          </div>
          <div className="w-full mt-4 ">
            <input  {...phone} placeholder={t('phone')} type="tel" className="w-full  p-2 px-4 mt-4" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
          </div>
          <div className="w-full flex mt-8">
            <textarea className="w-full h-48 resize-none p-4"  {...message} placeholder={t('message')} ></textarea>
          </div>
          <div className="w-full mt-8">
            <button className="btn font-bold block w-full" onClick={handleSend}>
              {loading ? 'Loading' : <span>{t('Send_Message')} </span>
              }
            </button>
          </div>
        </form>
    </div>
  )
}
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
export default Form