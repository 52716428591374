import { Link } from 'react-router-dom'
const ServiceCard = (props) => {
          return (
                    <>
                              <div className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                                        <Link to={`/services/${props.id}/${props.slug}`}>
                                                  <div className="p-4 pt-16 relative bg-white rounded-2xl border-2  border-gray-900 my-12">
                                                            <div className="productImag h-40 w-40 mb-4 p-4 absolute -top-24  left-1/2 -translate-x-1/2 rounded-full flex justify-center items-center">
                                                                      <img className="bg-white object-cover max-h-full max-w-full mx-auto" src={props.icon} alt={props.name} />
                                                            </div>
                                                            <div className="p-6 h-68 relative overflow-hidden text-center ">
                                                                      <div>
                                                                                {/* <span className="text-xl font-bold text-gray-800 uppercase">{props.category}</span> */}
                                                                                <span className=" whitespace-nowrap text-ellipsis relative overflow-hidden w-full block mt-2 text-3xl font-semibold text-gray-800 transition-colors duration-200 transform gray-800 hover:text-gray-600">{props.name}</span>
                                                                                <p className="mt-2 text-2xl leading-10 text-gray-900 h-32 relative overflow-hidden  text-overflow lines three"> {props.description} </p>
                                                                      </div>
                                                            </div>
                                                  </div>
                                        </Link>
                              </div>
                    </>

          )
}

export default ServiceCard