
import './card.scss'


const Card = (props) => {

          return (
                    <>

                              <div className="w-full md:w-1/2 mt-8  card p-4 py-16">
                                        <div className="subTitle font-bold">
                                                  <p className='text-3xl flex items-end pb-8 mb-8 text-white relative font-black'>{props.title}</p>
                                                  <q className='text-white font-bold text-2xl'>{props.subTitle}</q>
                                        </div>
                                        <div className="description text-white">
                                                  <p>{props.description}</p>
                                        </div>
                                        <div className="getQuote">
                                                  {props.link ?
                                                            <a href={props.href} className="btn font-bold inline-flex" onClick={props.action}>{props.button} </a>
                                                            :
                                                            <button href={props.href} className="btn font-bold" onClick={props.action}>{props.button} </button>
                                                  }

                                        </div>
                              </div>
                    </>

          )
}

export default Card