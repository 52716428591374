import img1 from '../../img/about1.webp'
import img2 from '../../img/about2.webp'
// import img3 from '../../img/about3.jpg' 
import img4 from '../../img/about4.webp'
import Title from '../UI/Title/Title'
import './About.scss'
import { useTranslation } from "react-i18next"
const About = () => {
          const { t } = useTranslation()
          return (
                    <section id='aboutSection'>
                              <div className="container mx-auto mb-24">
                                        <div className="row flex items-start justify-start  flex-wrap">
                                                  <div className="w-full md:w-1/2 md:py-4 mb-8 md:mb-0">
                                                            <Title className="blueColor" subTitle={t('about_page')} title={t('BrainClick')} description='' />
                                                  </div>
                                        </div>
                                        <div className="row flex items-start justify-start  flex-wrap">
                                                  <div className="w-full md:w-1/2 md:py-4 mb-8 md:mb-0 pr-4">
                                                            <div className="imageItem mainImage">
                                                                      <img src={img4} alt="" className='h-full w-full object-cover' />
                                                            </div>
                                                  </div>
                                                  <div className="w-full md:w-1/2 md:py-4 mb-8 md:mb-0">
                                                            <div className="imageItem sub h-96 relative overflow-hidden pb-4">
                                                                      <img src={img1} alt="" className='object-cover h-full min-w-full ' />
                                                            </div>
                                                            <div className="imageItem sub  h-96 relative overflow-hidden">
                                                                      <img src={img2} alt="" className='object-cover h-full min-w-full ' />
                                                            </div>
                                                  </div>
                                        </div> 
                              </div>
                    </section>
          )
}

export default About