import './SocialMedia.scss'
import {FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
// import { RiMessengerLine } from 'react-icons/ri'
import { ImWhatsapp } from 'react-icons/im'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal/Modal'
import { useState } from 'react'
import Form from '../Form/Form'
const SocialMedia = () => {
          const [isOpen, setIsOpen] = useState(false)
          const { t } = useTranslation()
          return (
                    <>
                              <div onClick={() => console.log('clicked')}>
                                        <Modal open={isOpen} onClose={() => setIsOpen(false)} >
                                                  <Form />
                                        </Modal>
                              </div>
                              <section className='z-10 leftSection fixed left-0 top-1/2  text-white -translate-y-1/2 hidden lg:block'>
                                        {/* <a href="#!" className='link m-8 -rotate-90 flex text-3xl'> <FaBehance /> </a> */}
                                        <a href="https://www.facebook.com/brainclickads" className='link m-8 -rotate-90 flex text-3xl'> <FaFacebookF /> </a>
                                        <a href="https://www.instagram.com/brainclicksm/" className='link m-8 -rotate-90 flex text-3xl'> <FaInstagram /> </a>
                                        <a href="https://twitter.com/brainclicksm" className='link m-8 -rotate-90 flex text-3xl'> <FaTwitter /> </a>
                                        <a href="https://www.linkedin.com/company/brain-click-uae" className='link m-8 -rotate-90 flex text-3xl'> <FaLinkedinIn />  </a>
                              </section>

                              <section className='z-10 rightSection fixed  items-center right-16 top-1/2 text-white translate-x-1/2  -translate-y-1/2 -rotate-90 hidden lg:flex'>

                                        <a href="https://api.whatsapp.com/send?phone=971529962679" target='_blank' rel='noreferrer'  className='btn flex mx-4 rotate-90'>
                                                  <ImWhatsapp />
                                        </a>
                                        <a href='#!' className="btn flex mx-4 " onClick={() => setIsOpen(true)}>
                                                  {t('Get_Free_Quote')}
                                        </a>
                                        {/* <a href='#!' className="btn flex mx-4 rotate-90 messenger">
                                                  <RiMessengerLine />
                                        </a> */}
                              </section>
                    </>
          )
}

export default SocialMedia