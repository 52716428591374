import { useState } from 'react'
import lazy from "react-lazy-with-preload";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { RiPhoneLine } from 'react-icons/ri'
import { AiOutlineMail } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'
import { ImWhatsapp } from 'react-icons/im'
import { useTranslation } from "react-i18next"
import './Footer.scss'
import { Link } from 'react-router-dom';
import Modal from '../Modal/Modal'
import Form from '../Form/Form'

const Map = lazy(() => import('./Map'));
Map.preload();


const Footer = () => {
  const { t } = useTranslation()
  const [IsOpen, setIsOpen] = useState(false)
  return (
    <>
      <div onClick={() => console.log('clicked')}>
        <Modal open={IsOpen} onClose={() => setIsOpen(false)} >
          <Form />
        </Modal>
      </div>
      <div id="Footer" className='pb-8 md:pb-0'>
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap py-12">
            <div className="w-full sm:w-1/2  py-12 pr-4">
              <h3 className="font-black text-white text-2xl xl:text-4xl w-full"> {t('United Kingdom')} </h3>
              <p className='text-white font-bold rtl:text-right mb-8 ltrDir'>{t("Seymour Grove, Old Trafford, Stretford, Manchester, UK")} </p>
              <Map dubai={false} />
            </div>
            <div className="w-full sm:w-1/2  py-12 pr-4">
              <h3 className="font-black text-white text-2xl xl:text-4xl w-full"> {t('United Arab Emirates')} </h3>
              <p className='text-white font-bold mb-8'>{t('Iris_Bay_Tower,_Business_Bay_-_Dubai')}</p>
              <Map dubai={true} />
            </div>
          </div>

          <div className="row flex rtl:text-right ltr:text-left items-start justify-center md:justify-start md:text-left flex-wrap py-12">
            <div className="w-full sm:w-1/2 lg:w-1/5 py-12 pr-4">
              <h3 className="font-black text-white text-2xl xl:text-4xl mb-8"> {t('All_Rights_Reserved')} </h3>
              <button className="btn lineBtn my-0 mb-4 xl:my-8 xl:font-bold flex mx-auto ltr:md:ml-0 ltr:md:mr-auto rtl:md:mr-0 rtl:md:ml-auto " onClick={() => setIsOpen(true)}> {t('Make_Inquiry')} </button>
              <div className="socialMedia flex text-white justify-center md:justify-start text-center md:text-left">
                {/* <Link to="/" className='link m-4 ml-0 flex text-3xl'> <FaBehance /> </Link> */}
                <a href="https://www.facebook.com/brainclickads" className='link m-4 flex text-3xl'> <FaFacebookF /> </a>
                <a href="https://www.instagram.com/brainclicksm/" className='link m-4 flex text-3xl'> <FaInstagram /> </a>
                <a href="https://twitter.com/brainclicksm" className='link m-4 flex text-3xl'> <FaTwitter /> </a>
                <a href="https://www.linkedin.com/company/brain-click-uae" className='link m-4 flex text-3xl'> <FaLinkedinIn />  </a>

              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 py-12 pr-4">
              <h3 className="font-black text-white text-2xl xl:text-4xl mb-8"> {t('CONTACT_US')} </h3>
              <ul className='leading-loose text-center md:text-left justify-center flex flex-col items-center md:items-start'>

                <li>
                  <a href="mailto:info@brainclickads.com" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'> <AiOutlineMail className='mr-4 text-cyan-500' /> info@brainclickads.com</a>
                </li>
                {/* <li>
                  <a href="https://www.google.com/maps/place/Brain+Click+Ads/@25.1860377,55.2595437,19.25z/data=!4m12!1m6!3m5!1s0x0:0xc9891260a62ede50!2sBrain+Click+Ads!8m2!3d25.1859019!4d55.2602652!3m4!1s0x0:0xc9891260a62ede50!8m2!3d25.1859019!4d55.2602652" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'> <GoLocation className='mr-4 text-cyan-500' /> {t('Iris_Bay_Tower,_Business_Bay_-_Dubai')} </a>
                </li> */}
                <li>
                  <a href="https://api.whatsapp.com/send?phone=971529962679" target='_blank' className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6' rel="noreferrer"> <ImWhatsapp className='mr-4 text-cyan-500' />  <span dir="ltr"> +971529962679</span>  </a>
                </li>
                <li>
                  <a href="tel:+971 4 568 7471" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'> <RiPhoneLine className='mr-4 text-cyan-500' /> <span dir="ltr">+97145126873</span>  </a>
                </li>
              </ul>
            </div>
            <div className="w-1/2 lg:w-1/5 py-12 pr-4">
              <h3 className="font-black text-white text-2xl xl:text-4xl mb-8 text-left"> {t('OUR_SERVICES')} </h3>
              <ul>
                <li>
                  <Link to={`/services/27/animation-design`} className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'> {t('service_two')} </Link>
                </li>
                <li>
                  <Link to={`/services/3/web-design`} className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'> {t('service_three')} </Link>
                </li>
                <li>
                  <Link to={`/services/20/lead-generation`} className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'> {t('service_one')} </Link>
                </li>
                <li>
                  <Link to={`/services/14/seo`} className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'> {t('service_fourth')} </Link>
                </li>
              </ul>
            </div>
            <div className="w-1/2 lg:w-1/5 py-12 pr-4">
              <h3 className="font-black text-white text-2xl xl:text-4xl mb-8 text-left"> {t('our_pages')} </h3>
              <ul>
                <li>
                  <Link to="/about" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'>   {t('About')}  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'>   {t('Privacy policy')}  </Link>
                </li>
                <li>
                  <Link to="/contact" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'>   {t('Contact')} </Link>
                  {/* <Link to="/" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'>   {t('Blog')} </Link> */}
                </li>
                <li>
                  <Link to="/services" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'>   {t('Services')} </Link>
                </li>
                <li>
                  <Link to="/portfolios" className='text-white flex items-center justify-start text-2xl xl:text-3xl mb-6'>   {t('Portfolio')}  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/5 py-12 pr-4">
              <ul className="flex items-center justify-start flex-wrap text-center rtl:md:text-right ltr:md:text-left text-white">
                <h3 className="font-black text-white text-2xl xl:text-4xl mb-8 text-center rtl:md:text-right ltr:md:text-left w-full">{t('Work_Times')}</h3>
                <li className='w-full mb-4'>{t('Monday')}</li>
                <li className='w-full mb-4'>{t('Tuesday')}</li>
                <li className='w-full mb-4'>{t('Wednesday')}</li>
                <li className='w-full mb-4'>{t('Thursday')}</li>
                <li className='w-full mb-4'>{t('Friday')}</li>
                <li className='w-full mb-4'>{t('Saturday')}</li>
                <li className='w-full mb-4'>{t('Sunday')}</li>
                {/* <li className='mb-4'>{t('Work_hours')}</li> */}
                {/* <li className='mb-4'>{t('Work_off')}</li> */}
              </ul>
            </div> 
          </div>
          <strong className='text-white text-center pb-8 block'> {t('All_Rights_Reserved')}
            <a href="http://brainclickads.com" className='text-cyan-600'>
              {t('Brain_Click')}</a> ©
            {new Date().getFullYear()}
          </strong>
        </div>
      </div>
    </>
  )
}

export default Footer