import { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../img/Logo.webp'
import LogoAR from '../../img/logo-ar.png'
import { RiPhoneLine } from 'react-icons/ri'
import { useTranslation } from "react-i18next"
import Lang from './lang/Lang'
import Form from '../UI/Analyze/Form'

const ScreenNav = () => {
  const { t } = useTranslation()
  const [scroll, setScroll] = useState(false);
  const [analyze, setAnalyze] = useState(false);
  window.addEventListener("scroll", () => {
    setScroll(window.scrollY > 500);
  });

  return (
    <>
      {analyze && <Form CloseModal={() => setAnalyze(false)} />}
      <button className="glow-on-hover fixed bottom-4 ltr:left-4 rtl:right-4 bg-cyan-500 font-bold px-8 py-4" type="button" onClick={() => setAnalyze(true)}>{t('Analyze your website for free')}</button>
      <nav className={"absolute w-full z-20 hidden md:flex " + (scroll ? "active top-0 left-0 z-50" : "")}>
        <div className='container mx-auto'>
          <nav className="flex items-center justify-between flex-wrap  p-6">
            <div className="w-full block md:flex md:items-center md:w-auto">
              <div className="text-sm md:flex-grow">
              </div>
              <div>
                <ul className="flex  justify-start items-center mb-8">
                  <a href="tel:+971529962679" className="block m-4 md:inline-block md:mt-0 btn "> {t('Ask')} </a>
                  <Lang />
                  <a href="tel:+97145126873" className='text-white flex items-center text-3xl mr-4'> <RiPhoneLine className='mr-4' /> +97145126873  </a>
                </ul>
                <ul className='flex'>
                  <Link to="/" className="link block m-4 md:inline-block md:mt-0 text-white "> {t('Home')}  </Link>
                  <Link to="/services" className="servicesLink link block m-4 md:inline-block md:mt-0 text-white"> <div className="flex items-center">{t('Services')} </div>  </Link>
                  {/* <Link to="/" className="link block m-4 md:inline-block md:mt-0 text-white"> {t('Products')}</Link> */}
                  <Link to="/portfolios" className="link block m-4 md:inline-block md:mt-0 text-white"> {t('Portfolio')}   </Link>
                  <a href='https://blogs.brainclickads.com/' className="link block m-4 md:inline-block md:mt-0 text-white"> {t('Blog')}   </a>
                  <Link to="/about" className="link block m-4 md:inline-block md:mt-0 text-white"> {t('About')}   </Link>
                  <Link to="/contact" className="link block m-4 md:inline-block md:mt-0 text-white"> {t('Contact')}   </Link>
                </ul>
              </div>
            </div>
            <div className="flex items-center flex-shri text-center text-white p-1nk-0 mr-6">
              <Link to='/' className="font-semibold block mt-4 md:inline-block md:mt-0 mr-4">
                <img src={LogoAR} alt="" className='mx-auto w-52 LogoAR' />
                <img src={Logo} alt="" className='mx-auto w-52 logoEn' />
              </Link>
            </div>


          </nav>
          {/* <div className={"navServices w-full left-0 absolute overflow-hidden " + (hovered ? " opend" : "  ")}  >
            <div className="container mx-auto ">
              <div className="row flex items-center justify-start flex-wrap pb-8  " style={{ padding: '0' }} onClick={closeHover}>
                <div className="w-full lg:w-5/6 xl:w-3/4" style={{ backgroundColor: '#012840' }} >
                  <div className="row flex items-center justify-center flex-wrap pb-8  " style={{ padding: '0' }} onClick={closeHover}>

                    {links}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    </>
  )
}

export default ScreenNav