import { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import thanks from '../img/email-thanks.webp'
// import PageHeader from '../Components/UI/PageHeader/PageHeader'
import { useTranslation } from "react-i18next"
import Title from '../Components/UI/Title/Title'

const ThanksPage = () => {
    const { t } = useTranslation()
    const [data , setData] = useState({})
    useEffect(() => {
        document.title = t('Thanks_page')
    }, [t]);
    // useEffect(() => {
    //     const t = localStorage.getItem('form')
    //     if (t) {
    //         setData(localStorage.getItem('form'))
    //         localStorage.removeItem('form')
    //         console.log(JSON.parse(t))
    //     }
        
    // },[])
    return (
        <Layout>
            {/* <PageHeader image={privacy} title={t('Terms_page')} /> */}
            <section className=' py-40 md:py-80'>
                <div className="container mx-auto">
                    <div className="row flex items-center justify-between flex-wrap">
                        <div className="w-full md:w-1/2 p-4 text-center">
                            <img src={thanks} alt="" className="w-3/5 mx-auto" />
                        </div>
                        <div className="w-full md:w-1/2 p-4 pt-40">
                            <Title className='text-black' title={t('thank you')} description='' />
                            <p className='leading-loose font-bold text-gray-800 mb-40'>{t('Your message has been sent successfully')}</p>
                            <ul className='flex items-start justify-between flex-wrap text-center my-12 w-full lg:w-1/2 '>
                                <li className='w-1/2 text-start'>
                                    <a href="tel:+971 4 568 7471 ">
                                        <p className='font-bold mb-4'>{t('Share_your_ideas')}</p>
                                        <p style={{ direction: 'ltr' }}>+971 4 568 7471 </p>
                                    </a>
                                </li>
                                <li className='w-1/2 text-end'>
                                    <a href='tel:+971 52 996 2679'>
                                        <p className='font-bold mb-4'>{t('Want_to_Discuss')}  </p>
                                        <a style={{ direction: 'ltr' }} href="tel:+971 52 996 2679" >+971 52 996 2679</a>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
        </Layout>
    )
}

export default ThanksPage