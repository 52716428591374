import React from 'react'
import ReactDOM from 'react-dom';
 import {GrClose} from 'react-icons/gr'
const Modal = ({ open, children, onClose }) => {


  if (!open) return null
  return ReactDOM.createPortal(
    <>
      <div className='overlay inset-0 bg-black opacity-80 z-50 fixed' onClick={onClose} />
      <div className='rounded-xl fixed w-10/12 md:w-2/6 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 md:p-12 z-50 bg-white '>
        <button className='btn hover:text-white closeBtn' onClick={onClose}>
          <GrClose />
        </button>
        {children}
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default Modal  