import './Plans.scss'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import Modal from '../Modal/Modal'
import { useState } from 'react'
import Form from '../Form/Form'

const Card = (props) => {
  const { t } = useTranslation()
  const [IsOpen, setIsOpen] = useState(false)
  return (
    <>
      <div onClick={() => console.log('clicked')}>
        <Modal open={IsOpen} onClose={() => setIsOpen(false)} >
          <Form />
        </Modal>
      </div>
      <div className='planCard p-8 md:mx-4'>
        <div className="subTitle font-black text-5xl text-black">
          <h2>{props.name}</h2>
        </div>
        <div className="blueColor my-4  overflow-hidden h-20">
          <p>{props.subtitle} </p>
        </div>
        {/* <div className="price flex items-center">
        <div className="newPrice blueColor font-black text-7xl pr-6 mr-6 border-r-4 border-black">
          <h2>$250</h2>
        </div>
        <div className="oldPrice text-red-800 font-bold text-4xl line-through">
          <h2>$500</h2>
        </div>
      </div> */}
        <div className="features mt-16">
          <ul className="listItem">
            {
              props.services.map((item, index) => (
                <li key={index} className='flex items-center my-8 text-3xl relative'>
                  <span className='w-4 h-4 bg-cyan-500 rounded-lg inline-block mr-4'></span>
                  <span key={index} className="w-10/12">  {item.name} </span>
                  <BsFillPatchCheckFill className='absolute right-4 text-cyan-500 ' />
                </li>
              ))
            }
          </ul>
        </div>
        <div className="btns text-center flex justify-center items-center flex-wrap  w-full  border-b-2 border-b-cyan-500 pb-8 mb-8">
          <button onClick={() => setIsOpen(true)} className='flex items-center btn mt-8 font-bold '> {t('Order_Now')}  <AiOutlineArrowRight className='ml-4' /> </button>
          <Link to='/portfolio' className='w-full font-bold text-black mt-4'>  {t('View_Our_Portfolio')}   </Link>
        </div>
        <div className="contact">
          <ul className='flex items-center justify-between flex-wrap text-center '>
            <li className='w-full md:w-1/2 md:border-r-2 md:border-r-cyan-500'>
              <div >
                <p className='font-bold mb-4'>{t('Share_your_ideas')}</p>
                <a href="tel:+971 4 568 7471 ">+971 4 568 7471 </a>
              </div>
            </li>
            <li className='w-full md:w-1/2'>
              <div>
                <p className='font-bold mb-4'>{t('Want_to_Discuss')}  </p>
                <a href="tel:+971 52 996 2679" >+971 52 996 2679</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Card