import { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import ServiceCard from '../Components/ServiceCard/ServiceCard'
import { useTranslation } from "react-i18next"
import { config, url } from '../../src/Components/Utils/Common'
import axios from 'axios';
import Loader from '../Components/UI/Loader/Loader'
const ServicesPage = () => {
  const [services, setServices] = useState(Array.from(Array(5).keys(), n => n + 1));
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation()

  useEffect(() => {
    document.title = t('services_page')
    axios(url + 'services', config).then(response => {
      if (response.status === 404) {
        setIsNotFound(true);
        return;
      }

      if (response.status === 400) {
        setIsNotValid(true);
        return;
      }

      const data = (response.data.data)
      setServices(data)
      setIsLoading(false);
    }).catch(error => {
      console.log(error)
    })

  }, [t]);

  if (isNotFound) {
    return (
      <>
        <p className="error">Sorry! We can't find that reservation.</p>
      </>
    );
  }

  if (isNotValid) {
    return (
      <>
        <p className="error">Sorry! The reservation ID is invalid.</p>
      </>
    );
  }

 

  return (
    <Layout >
      <div id="servicesPage" className='pt-8 md:pt-80'>
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap">
            {
              services.map(service => (
                <ServiceCard name={service.name} icon={service.icon} description={service.description} category={service.category} key={service.id} id={service.id} slug={service.slug} />
              ))
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ServicesPage