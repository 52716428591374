import  { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { AiOutlineMinus } from 'react-icons/ai'
import './FAQ.scss'

const FAQ = (props) => {
          const [show, setShow] = useState();
          return (
                    <div className="space-y-8 " key={props.id}>


                              <div className={` bg-gray-100 rounded-lg mb-4  ease-out duration-1000  overflow-hidden  ${show ?   "active" : ""}`} onClick={() => setShow(!show)} key={props.id}>
                                        <button className=" px-8 py-8 flex items-start justify-between w-full">
                                                  <h1 className="text-left font-semibold blueColor ">{props.title}</h1>

                                                  <span className={`text-black    rounded-md text-4xl ${show ?  'bg-white ' : ' bg-cyan-500 ' } `}>
                                                            {show ? <AiOutlineMinus /> : <MdOutlineKeyboardArrowDown />}
                                                  </span>
                                        </button>

                                        <p className={`px-8 pb-8 text-2xl text-black description ${show ? 'flex' : ' hidden' }`}>
                                                  {props.description}
                                        </p>
                              </div>
                    </div>
          )
}

export default FAQ