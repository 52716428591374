import { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import Statistics from '../Components/Statistics/Statistics'
import Strategies from '../Components/Strategies/Strategies'
import Idea from '../Components/Idea/Idea'
import Frequently from '../Components/Frequently/Frequently'
import { useTranslation } from "react-i18next"
import { config, url } from '../Components/Utils/Common'
import axios from 'axios';
import Loader from '../Components/UI/Loader/Loader'
import Team from '../Components/Team/Team'
import privacy from '../img/policy-icon.png'
const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [main, setMain] = useState({});

  useEffect(() => {
    document.title = t('about_page')
    axios(url + 'pages/about', config).then(response => {
      if (response.status === 404) {
        setIsNotFound(true);
        return;
      }

      if (response.status === 400) {
        setIsNotValid(true);
        return;
      }

      const data = (response.data.data)
      setMain(data)
      setIsLoading(false);
    }).catch(error => {
      console.log(error)
    })

  }, [t]);


  if (isNotFound) {
    return (
      <>
        <p className="error">Sorry! We can't find that reservation.</p>
      </>
    );
  }

  if (isNotValid) {
    return (
      <>
        <p className="error">Sorry! The reservation ID is invalid.</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  const about = main.paragraphs
  let aboutBlocks = <p>No Items </p>;
  if (about.length > 0) {
    aboutBlocks = about.map((item, index) => (
      <div className="w-full  lg:w-1/3  md:py-4 md:px-32 md:pl-0 mb-16" key={index}>
        <h3 className='font-bold text-4xl blueColor'>{item.title} </h3>
        <div className="my-4  blueColor">
          <p> {item.content}</p>
        </div>
      </div>
    ))
  }


  return (
    <Layout>
      <section className='md:pt-80 min-h-screen'>
        <div className="container mx-auto my-12">
          <div className="mainTitle blueColor">
            <h2> {t('Privacy policy')}</h2>
          </div>
          <div className="w-full">
            <img src={privacy} alt="" className='mx-auto' />
          </div>
          <div className="w-full lg  md:py-4 md:px-32 md:pl-0 mb-16">
            <div className="mainTitle blueColor">
              <h2> {t('Strategic Planning')}</h2>
            </div>
          </div>
          <div className="row flex items-stretch justify-start  flex-wrap mt-12 aboutData md:pr-32 ">

            <div className="w-full md:w-1/3  md:mb-0 p-4 md:p-8">
              <h1 className='font-bold text-4xl blueColor mb-4'>{t('Who')} </h1>
              <p className='description '>  {t('88')} </p>
            </div>
            <div className="w-full md:w-1/3  md:mb-0 p-4 md:p-8">
              <h1 className='font-bold text-4xl blueColor mb-4'>{t('99')}</h1>
              <p className='description '>  {t('9')}</p>

            </div>
            <div className="w-full md:w-1/3  md:mb-0 p-4 md:p-8">
              <h1 className='font-bold text-4xl blueColor mb-4'>{t('10')}</h1>
              <p className='description '>{t('8')}</p>

            </div>
          </div>
        </div>
        <div className="container mx-auto my-16">
          <div className="row flex items-center justify-center flex-wrap ">
            <div className="w-full lg  md:py-4 md:px-32 md:pl-0 mb-16">
              <div className="subTitle mb-4">
                <p className='text-2xl md:text-3xl flex items-center blueColor'>
                  <span className=' relative flex mr-4 overflow-hidden h-1 w-40'>.</span>
                  {main.header}
                </p>
              </div>
              <div className="mainTitle blueColor">
                <h2> {main.title}</h2>
              </div>
              <div className="my-4  blueColor">
                <p> {main.subtititle}</p>
              </div>
            </div>
            {aboutBlocks}
          </div>
        </div>
      </section>
      <Statistics />
      <Frequently />
      <Strategies />
      <section>
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap ">
            <div className="w-full lg  md:py-4 md:px-32 md:pl-0 mb-16">
              <Idea />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy