import { useState } from 'react'
import Form from '../../Form/Form'
import Modal from '../../Modal/Modal'

const PortfolioCard = (props) => {
     const [isOpen, setIsOpen] = useState(false)

     return (
          <div className={`my-4 px-4 md:p-8 relative ${props.class}`}>
               <div onClick={() => console.log('clicked')}>
                    <Modal open={isOpen} onClose={() => setIsOpen(false)} >
                         <Form />
                    </Modal>
               </div>
               {props.type == 'web' ?

                    <>
                         <a href={props.url} className='flex relative mb-16 md:mb-24'>
                              <img src={props.image} alt={props.name} className='' />
                              <h3 className='absolute -bottom-20 rounded-lg w-full text-center border-2 border-black p-2'>{props.name}</h3>
                         </a>
                    </>
                    :
                    (
                         (props.type != "mobile" && props.type != "video" && props.type != "video_montage") ?
                              <div className=" ">
                                   <a href={props.url} >
                                        <div className="">
                                             {props.type == "system" ?
                                                  <>
                                                       <img src={props.image} alt={props.name} className='rounded-xl flex mb-8' onClick={() => setIsOpen(true)} />
                                                       <div className='mb-16 border-2 border-black py-2 px-2 xl:px-8 bg-white rounded-xl text-black overflow-hidden'>
                                                            <span>
                                                                 {props.name}
                                                            </span>
                                                       </div>
                                                  </>
                                                  :
                                                  <img src={props.image} alt={props.name} className='rounded-xl' />
                                             }
                                        </div>
                                   </a>

                              </div>
                              :
                              (props.type == "mobile") &&
                              <div className="flex flex-wrap justify-center relative ">
                                   {props.image_android && props.image_ios &&
                                        <>
                                             <h3 className="text-center w-6/12 border-2 border-black border-b-0 relative whitespace-nowrap text-ellipsis h-12">
                                                  <span className='bg-white rounded-xl px-8 border-2 border-black py-2 text-black overflow-hidden absolute -mt-6 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>{props.description}</span>
                                             </h3>
                                             <div className="flex -mt-12 z-10">
                                                  <a href={props.url_android} >
                                                       <div className=" w-full h-full">
                                                            <img src={props.image_android} alt={props.name} className='' />
                                                       </div>
                                                  </a>
                                                  <a href={props.url_ios} >
                                                       <div className=" w-full h-full">
                                                            <img src={props.image_ios} alt={props.name} className='' />
                                                       </div>
                                                  </a>
                                             </div>
                                        </>
                                   }
                              </div>

                    )
               }

               {
                    (props.type == "video" || props.type == "video_montage") &&
                    <div className="itemVideo">
                         {
                              <div className="video-responsive">
                                   <iframe
                                        width="1280"
                                        height="720"
                                        src={`https://www.youtube.com/embed/${props.youtube_link}`}
                                        title={props.name}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                   ></iframe>
                              </div>
                         }

                    </div>

               }
          </div >
     )
}

export default PortfolioCard