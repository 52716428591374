import './i18n';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import './Style/App.scss'
import './RTL.scss';
import './index.scss'
console.log = console.warn = console.error = () => { };
console.error('Something bad happened.');
const Loading = <></>
ReactDOM.render(<Suspense fallback={Loading}> <App /></Suspense>, document.getElementById('root')); 