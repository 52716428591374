import './Banner.scss'
import banner from '../../img/Banner.jpg'
import a from '../../img/icons/a.webp'
import b from '../../img/icons/b.webp'
import c from '../../img/icons/c.webp'
import d from '../../img/icons/d.webp'
import Lottie from 'react-lottie';
import animationData from './Icon.json'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import curve from '../../img/curve.png'
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
const Banner = (props) => {

  return (
    <div className='mb-24'>
      <section id='Banner' className='Banner bg-no-repeat object-cover bg-bottom bg-cover relative overflow-hidden' style={{ backgroundImage: `url(${banner})` }}>
        <div className="container mx-auto flex items-center justify-center h-full">
          <div className="aboutBrainClick text-center text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <h3 className='font-bold text-7xl mb-4'>{t('brain_click')}</h3>
            <p className=' text-3xl capitalize leading-10 mb-4' style={{lineHeight: '1.8'}}>{t('descriptionBanner')}</p>
          </div>
        </div>
        <img src={curve} alt='curve' className="curve absolute opacity-100 -bottom-48 left-0 w-full -scale-y-100"  />
      </section>
      <div className="container mx-auto -mt-32">
        <div className="row flex items-start lg:items-center justify-between flex-wrap">
          <div className="w-1/2 lg:w-1/4">
            <Link to={`/services/20/lead-generation`} className="iconHeader flex items-center justify-center  flex-col" >
              <img src={a} alt="" className='absolute w-52 lg:w-64 z-10' />
              <Lottie options={defaultOptions} style={{ transform: 'rotate(180deg)' }} />
              <h3 className='font-bold text-2xl md:text-3xl text-center'>{t('service_one')}</h3>
            </Link>
          </div>
          <div className="w-1/2 lg:w-1/4">
            <Link to={`/services/27/animation-design`} className="iconHeader flex items-center justify-center flex-col">
              <img src={b} alt="" className='absolute w-52 lg:w-64 z-10' />
              <Lottie options={defaultOptions} style={{ transform: 'rotate(90deg)' }} />
              <h3 className='font-bold text-2xl md:text-3xl text-center'>{t('service_two')}</h3>
            </Link>
          </div>
          <div className="w-1/2 lg:w-1/4">
            <Link to={`/services/3/web-design`}  className="iconHeader flex items-center justify-center  flex-col" >
              <img src={c} alt="" className='absolute w-52 lg:w-64 z-10' />
              <Lottie options={defaultOptions} style={{ transform: 'rotate(45deg)' }} />
              <h3 className='font-bold text-2xl md:text-3xl text-center'>{t('service_three')}</h3>
            </Link>
          </div>
          <div className="w-1/2 lg:w-1/4">
            <Link to={`/services/7/media-production`} className="iconHeader flex items-center justify-center  flex-col" >
              <img src={d} alt="" className='absolute w-52 lg:w-64 z-10' />
              <Lottie options={defaultOptions} />
              <h3 className='font-bold text-2xl md:text-3xl text-center'>{t('media production')}</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Banner