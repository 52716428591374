import axios from 'axios'
import { t } from 'i18next'
import { useState } from 'react'
import { config, url } from '../../Utils/Common'
import Lottie from 'react-lottie';
import Confirm from '../../Lottiefiles/Confirm.json';
import {AiOutlineCloseCircle} from 'react-icons/ai'
const Form = (props) => {
          const [success, setSuccess] = useState(false)
          const [fullName, setFullName] = useState('')
          const [companyName, setCompanyName] = useState('')
          const [email, setEmail] = useState('')
          const [phone, setPhone] = useState('')
          const [link, setLink] = useState('')

          const handleSend = (event) => {
                    console.log(fullName, companyName, email, phone, link)
                    event.preventDefault()
                    let data = new FormData();
                    data.append('name', fullName);
                    data.append('company', companyName);
                    data.append('email', email);
                    data.append('phone', phone);
                    data.append('website', link);

                    axios.post(url + 'analyse-request/store', data, config).then(response => {
                              console.log(response);
                              setSuccess(true)
                              setFullName('')
                              setCompanyName('')
                              setEmail('')
                              setPhone('')
                              setLink('')
                    }).catch(error => {
                              console.log(error)
                    })
          }

          const handleClose = () => {
                    props.CloseModal()
          }

          const defaultOptions = {
                    loop: true,
                    autoplay: true,
                    animationData: Confirm,
                    rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice'
                    }
          };

          return (
                    <>
                              <div className="modalBack fixed top-0 left-0 h-screen w-screen bg-black opacity-80 " onClick={handleClose} />
                              <div className="modal fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-6 py-12 rounded-xl ">
                                        {success ?
                                                  <div className="flex items-center justify-center flex-col">
                                                            <div className="h-full w-full ">
                                                                      <Lottie options={defaultOptions} height={200} width={200}  />
                                                            </div>
                                                            <h3 className='font-bold text-green-700 px-8'> {t('The request has been confirmed')}</h3>
                                                            <button className='font-bold text-red-700 px-8 mt-16' onClick={handleClose} >{t('close')}</button>
                                                  </div>
                                                  :
                                                  <form action="">
                                                            <button onClick={handleClose} className='text-red-700 text-4xl absolute rtl:left-4 ltr:right-4'> <AiOutlineCloseCircle /> </button>
                                                            <h3 className='font-bold  text-3 xl'> {t('Analyze your website for free')} </h3>
                                                            <div className="w-full mt-4">
                                                                      <input required onChange={(e) => setFullName(e.target.value)} placeholder={t('fullName')} className="w-full  p-2 px-4 mt-4" type="text" />
                                                            </div>
                                                            <div className="w-full mt-4">
                                                                      <input required onChange={(e) => setCompanyName(e.target.value)} placeholder={t('companyName')} type="text" className="w-full  p-2 px-4 mt-8 md:mt-4" />
                                                            </div>

                                                            <div className="w-full mt-4">
                                                                      <input required onChange={(e) => setEmail(e.target.value)} placeholder={t('email')} className="w-full  p-2 px-4 mt-8 md:mt-4" type="email" />
                                                            </div>
                                                            <div className="w-full mt-4 ">
                                                                      <input required onChange={(e) => setPhone(e.target.value)} placeholder={t('phone')} type="tel" className="w-full  p-2 px-4 mt-4" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                                                            </div>
                                                            <div className="w-full mt-4 ">
                                                                      <input required onChange={(e) => setLink(e.target.value)} placeholder={t('link')} type="url" className="w-full  p-2 px-4 mt-4" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                                                            </div>
                                                            <div className="w-full mt-4 ">
                                                                      <button className="btn font-bold block w-full" onClick={handleSend}> {t('Order Now')} </button>
                                                            </div>
                                                  </form>
                                        }
                              </div>
                    </>
          )
}

export default Form