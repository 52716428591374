import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import './Team.scss'
import axios from 'axios'
import { config, url } from '../Utils/Common'
const Team = () => {
     const [team, setTeam] = useState([])
     const [manager, setManger] = useState()

     useEffect(() => {
          axios(url + 'employees', config).then(response => {
               const data = (response.data.data)
               setTeam(data.employees)
               setManger(data.manager)
               console.log(data.team)
          }).catch(error => {
               console.log(error)
          })
     }, []);
     return (
          <div className="Team mb-64">
               <div className="container mx-auto">

                    <div className="row flex items-start justify-start  flex-wrap mt-12">
                         <div className="w-full lg  md:py-4 md:px-32 md:pl-0 mb-16">
                              <div className="subTitle mb-4">
                                   <p className='text-2xl md:text-3xl flex items-center blueColor'>
                                        <span className=' relative flex mr-4 overflow-hidden h-1 w-40'>.</span>
                                        {t('our team')}
                                   </p>
                              </div>
                              <div className="mainTitle blueColor">
                                   <h2>{t("We work with love and belief in the company's vision")}</h2>
                              </div>
                         </div>

                    </div>
                    {manager &&
                         <div className="row flex flex-wrap justify-center items-center text-center">
                              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 lg:px-8 my-8"  >
                                   <div className="imgCard manger  relative overflow-hidden rounded-xl border border-gray-100 shadow-xl drop-shadow-xl">

                                        <img src={manager.image} alt="" className='w-full min-h-full object-cover object-top' />
                                        <div className="detailsCard overflow-hidden  absolute bottom-0 w-full bg-white py-4">
                                             <h3 className='font-black text-3xl lg:text-4xl text-center tracking-wide'>{manager.name} </h3>
                                             <h3 className='font-black text-4xl text-center mt-4 opacity-75 text-cyan-600'> {manager.position} </h3>
                                        </div>
                                   </div>

                              </div>
                         </div>}

                    <div className="row flex flex-wrap justify-center items-center text-center">
                         {
                              team.map((item, index) => (

                                   <div className="w-1/2 md:w-1/3 lg:w-1/4 px-4 lg:px-8 my-8" key={index}>
                                        <div className="imgCard  relative overflow-hidden rounded-xl border border-gray-100 shadow-xl drop-shadow-xl">
                                             <img src={item.image} alt="" className='w-full min-h-full object-cover object-top' />
                                             <div className="detailsCard overflow-hidden  absolute bottom-0 w-full bg-white py-4">
                                                  <h3 className='font-black text-2xl lg:text-4xl text-center tracking-wide'>{item.position}</h3>
                                                  {/* <h3 className='font-medium text-3xl text-center mt-4 opacity-75 text-cyan-600'> {item.position} </h3> */}
                                             </div>
                                        </div>

                                   </div>
                              ))
                         }
                    </div>
               </div>
          </div>
     )
}

export default Team