import React from 'react'
import { Link } from 'react-router-dom'
import error from '.././img/404.png'
import { useTranslation } from "react-i18next"
const Error = () => {
  const { t } = useTranslation()
  return (

    <div className="Error flex items-center flex-col  justify-center h-screen overflow-hidden relative px-4 py-32">
      <img src={error} alt="" className=' mx-auto mb-24' />
      <Link to="/" className="btn lineBtn bg-yellow-500 xl:font-bold  "> {t('back_to_home')} </Link>
    </div>
  )
}

export default Error