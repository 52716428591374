import { GiPodiumWinner } from 'react-icons/gi'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { BsCodeSlash } from 'react-icons/bs'
import { BiBrain } from 'react-icons/bi'
import Title from '../UI/Title/Title'
import './Idea.scss'
import { useTranslation } from "react-i18next"
import { useState } from 'react'
import Modal from '../Modal/Modal' 
import Form from '../Form/Form'
// import { t } from 'i18next'
const Idea = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()
  return (
    <>
      <div onClick={() => console.log('clicked')}>
        <Modal open={isOpen} onClose={() => setIsOpen(false)} >
          <Form />
        </Modal>
      </div>
      <div id="Idea" className='my-12'>
        <div className="container mx-auto">
          <div className="row flex items-start justify-center flex-wrap">
            <div className="w-full lg:w-1/2 md:py-4 lg:pr-32 mb-8 md:mb-0">
              <Title className="blueColor" subTitle={t('dream')} title={t('“Create with the heart; build with the mind”')} small={t('Criss Jami')} description={t('Brain Click is an innovative collective of out-of-the-box thinkers dedicated to delivering comprehensive branding and creative solutions. With a team brimming with experience and boundless passion, we are committed to crafting awe-inspiring and meaningful creations. We deeply value the collaborative aspect of each project, viewing it as a true partnership where we delve into your vision and provide the necessary support to conquer any obstacles you encounter. At Brain Click, our process revolves around three fundamental steps: THINK – CREATE – OPTIMIZE, ensuring that every endeavor reaches its maximum potential.')}         />
              <div className="btns flex mt-8">
                {/* <a href="#!" className="btn  font-bold"> {t('Get_Free_Quote')} </a> */}
                <a href="tel:+971 52 996 2679" className="btn mx-4 lineBtn font-bold">{t('Call_Now_To_Discuss')} </a>
                <button onClick={() => setIsOpen(true)} className='btn'>{t('Get_Free_Quote')}</button>
              </div>
            </div>
            <div className="w-full lg:w-1/2 ">
              <div className="row flex items-start justify-center flex-wrap">
                <div className="w-full md:w-1/2 md:mt-8 md:p-4">
                  <div className="Icon">
                    <AiOutlineFileSearch className='yellowColor text-7xl mb-8' />
                  </div>
                  <div className="subTitle font-bold">
                    <p className='block text-2xl md:text-3xl md:flex items-end blueColor whitespace-nowrap text-ellipsis overflow-hidden w-full '>  {t('1')}</p>
                  </div>
                  <div className="description blueColor">
                    <p>{t('11')} </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 md:mt-8 md:p-4">
                  <div className="Icon">
                    <BiBrain className='yellowColor text-7xl mb-8' />
                  </div>
                  <div className="subTitle font-bold">
                    <p className='block text-2xl md:text-3xl md:flex items-end blueColor whitespace-nowrap text-ellipsis overflow-hidden w-full '>{t('2')} </p>
                  </div>
                  <div className="description blueColor">
                    <p> {t('22')} </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 md:mt-8 md:p-4">
                  <div className="Icon">
                    <BsCodeSlash className='yellowColor text-7xl mb-8' />
                  </div>
                  <div className="subTitle font-bold">
                    <p className='block text-2xl md:text-3xl md:flex items-end blueColor whitespace-nowrap text-ellipsis overflow-hidden w-full '> {t('3')}</p>
                  </div>
                  <div className="description blueColor">
                    <p> {t('33')}</p>
                  </div>
                </div>

                <div className="w-full md:w-1/2 md:mt-8 md:p-4">
                  <div className="Icon">
                    <GiPodiumWinner className='yellowColor text-7xl mb-8' />
                  </div>
                  <div className="subTitle font-bold">
                    <p className='block text-2xl md:text-3xl md:flex items-end blueColor whitespace-nowrap text-ellipsis overflow-hidden w-full '>{t('Embark on your project with confidence')}</p>
                  </div>
                  <div className="description blueColor">
                    <p>{t('At our agency, we take pride in our streamlined processes, setting us apart from the rest and enabling us to overcome the most significant challenge - meeting project deadlines.')} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Idea