import Card from './Card'
import Slider from "react-slick";
import axios from 'axios'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from '../UI/Title/Title'
import { useEffect, useState } from 'react';
import { config, url, Settings } from '../Utils/Common'
import { t } from 'i18next';
import Loader from '../UI/Loader/Loader';

const Plans = () => {
  const [packege, setPackege] = useState({})
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios.get(url + 'pricing', config).then(response => {
      if (response.status === 404) {
        setIsNotFound(true);
        return;
      }

      if (response.status === 400) {
        setIsNotValid(true);
        return;
      }

      const data = (response.data.data)
      setPackege(data)
      setIsLoading(false);
    }).catch(error => {
      console.log(error)
    })
  }, []);


  let packegePrice = <p> {t('we_dont_have')} </p>
  if (packege.length > 0) {
    packegePrice = packege.map((item, index) => (
      <div key={index} >
        <Card id={item.id} name={item.name} subtitle={item.subtitle} services={item.services} />
      </div>
    ))
  }


  if (isNotFound) {
    return (
      <>
        <p className="error">Sorry! We can't find that reservation.</p>
      </>
    );
  }

  if (isNotValid) {
    return (
      <>
        <p className="error">Sorry! The reservation ID is invalid.</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <Loader />
    )
  }



  return (
    <div id='Plans' className='my-16'>
      {
        packege.length > 0 &&
        <div className="container mx-auto">
          <div className="row flex items-start justify-start flex-wrap">
            <div className="w-full md:w-1/2 md:py-4   mb-8 md:mb-0">
              <Title className="blueColor" subTitle={t('PACKAGES')} title={t('Choose')} description={t('Making')} />
            </div>
          </div>
          <div>
            <Slider {...Settings}>
              {packegePrice}
            </Slider>
          </div>
        </div>
      }
    </div>

  )
}

export default Plans