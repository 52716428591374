import { useEffect } from "react";
import Layout from '../Components/Layout'
import Banner from '../Components/Banner/Banner'
import Idea from '../Components/Idea/Idea'
import Strategies from '../Components/Strategies/Strategies'
import Plans from '../Components/Plans/Plans'
import About from '../Components/About/About'
import Frequently from '../Components/Frequently/Frequently'
import Statistics from '../Components/Statistics/Statistics'
import Testimonials from '../Components/Testimonials/Testimonials'
import { useTranslation } from "react-i18next";
import Partners from "../Components/Parterns/Partners";
import Portfolios from "../Components/PortfoliosHome/Portfolios";
const HomePage = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = t('home_page')
  }, [t])
  return (
    <div id="HomePage">
      <Layout>
        <Banner />
        <Idea />
        <Strategies />
        <Portfolios />
        <Plans />
        <About />
        <Frequently />
        <Statistics />
        <Partners />
        <Testimonials />
      </Layout>
    </div>
  )
}

export default HomePage