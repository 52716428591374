// Return Config
export const config = {
     headers: {
          'Accept': 'application/json',
          // 'Accept-Language': localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'ar',
          'lang': localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en',

     },
}
// https://brainclickads.com/dashboard/index.php/api/v1/pricing
// Return URL Api
// export const url = 'https://brainclickads.com/dashboard/api/v1/'
export const url = 'https://backend.brainclickads.com/api/v1/'

// Return Settings Slider
export const Settings = {
     dots: false,
     infinite: false,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
     arrows: true,
     initialSlide: 0,
     centerPadding: "60px",
     responsive: [
          {
               breakpoint: 1800,
               settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
               }
          },
          {
               breakpoint: 1023,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
               }
          },
          {
               breakpoint: 600,
               settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
               }
          }
     ]
};