import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import Backend from 'i18next-http-backend';

i18n
.use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
   fallbackLng: "en",
    supportedLangs: ['en', 'ar'],
    debug: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['localStorage', 'path', 'htmlTag', 'subdomain'],
      caches: ['localStorage'], 
    },
    backend: {
      loadPath: '/assets/local/{{lng}}/translation.json',
    },
  });

export default i18n;