import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../Components/Layout'
// import services from '.././img/portfolio.jpg'
import Testimonials from '../Components/Testimonials/Testimonials'
// import PageHeader from '../Components/UI/PageHeader/PageHeader'
import Title from '../Components/UI/Title/Title'
import Plans from '../Components/Plans/Plans'
import PortfolioCard from '../Components/Tabs/AllTabs/PortfolioCard'
import ServiceCard from '../Components/ServiceCard/ServiceCard'
import { config, url } from '../../src/Components/Utils/Common'
import axios from 'axios';
import { t } from 'i18next'
import Fav from '../img/Logo.gif'

const ServiceItem = () => {
  const { id } = useParams();
  const [service, setServices] = useState({});
  const [relatedWork, setRelatedWork] = useState({});
  const [relatedServices, setRelatedServices] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const idCategory = window.location.pathname.split('/')[2]


  useEffect(() => {
    axios(url + `services/single/${idCategory}`, config).then(response => {
      if (response.status === 404) {
        setIsNotFound(true);
        return;
      }

      if (response.status === 400) {
        setIsNotValid(true);
        return;
      }
      const data = (response.data.data)
      console.log(data)
      setServices(data);
      setRelatedWork(data.related_work.slice(0, 8))
      setRelatedServices(data.related_services)
      console.log(data)
      setIsLoading(false);
    }).catch(error => {
      console.log(error)
    })
  }, [id]);



  let webScreens = <p>No Items </p>;
  if (relatedWork.length > 0) {
    webScreens = relatedWork.map((item) => (
      <PortfolioCard
        description={item.description}
        class={item.show_type == 'mobile' ? 'w-full lg:w-1/2 xl:w-1/3 ' : 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4 '}
        key={item.id}
        image={item.icon}
        url_android={item.url_android}
        url_ios={item.url_ios}
        url={item.url}
        id={item.id}
        type={item.show_type}
        image_android={item.image_android}
        image_ios={item.image_ios}
        isYoutube={item.youtube_link && true}
        video_link={item.video_link}
        youtube_link={item.youtube_link}
      />
    ))
  }

  let related_services = <p>No Items </p>;
  if (relatedServices.length > 0) {
    related_services = relatedServices.map((item) => (
      <ServiceCard key={item.id} name={item.name} description={item.description} id={item.id} slug={item.slug} icon={item.icon} />
    ))
  }


  if (isNotFound) {
    return (
      <>
        <p className="error">Sorry! We can't find that reservation.</p>
      </>
    );
  }

  if (isNotValid) {
    return (
      <>
        <p className="error">Sorry! The reservation ID is invalid.</p>
      </>
    );
  }


  return (
    <Layout>
      <div className="serviceItem pb-8 mb-8 md:pt-80">
        <div className="container mx-auto">
          <div className="row flex items-start justify-center flex-wrap">
            <div className="w-full md:w-1/2 p-0 md:p-8 xl:p-16">
              <div className="blueColor">
                {/* <Title className="blueColor" subTitle={service.category} title={service.name} /> */}
                <Title className="blueColor" subTitle={service.category} title={service.title_1} />
              </div>

            </div>

            <div className="w-full md:w-1/2 p-0 md:p-8 xl:p-16">
              <div className="description">
                <p className="mt-2 text-xl leading-10 text-gray-900  ">  {service.description_1} </p>
              </div>
            </div>
          </div>
          <div className="row flex items-start justify-center flex-wrap">
            <div className="w-full md:w-1/2 p-0 md:p-8 xl:p-16">
              <div className="mainTitle ">
                <h2>{service.title_2} </h2>
              </div>
              <div className="description">
                <p className="mt-2 text-xl leading-10 text-gray-900  ">     {service.description_2}  </p>
              </div>
            </div>

            <div className="w-full md:w-1/2 p-0 md:p-8 xl:p-16">
              <div className="serviceImage rounded-3xl p-4 relative overflow-hidden">
                <img className="w-full rounded-3xl  object-cover h-full mx-auto " src={service.image} alt={service.name} />
              </div>
            </div> 
          </div>
          <div className='ourProject mt-16'>
            <Title className="blueColor" subTitle={t('Portfolio')} title={t('')} />
            <div id="webDesign" className='singleTab mt-12 text-center'>
              <div className="row flex items-center justify-center flex-wrap">
                {webScreens}
              </div>
            </div>
          </div>
          <div className="row flex items-start justify-center flex-wrap ">
            <div className="w-full mt-24">
              <div className="mainTitle ">
                <h2> {service.title_3}</h2>
              </div>
              <div className="description">
                <p className="mt-2 text-xl leading-10 text-gray-900  ">{service.description_3} </p>
              </div>
            </div>
            <div className="w-full mt-24">
              <div className="mainTitle ">
                <h2> {service.title_4}</h2>
              </div>
              <div className="description">
                <p className="mt-2 text-xl leading-10 text-gray-900  ">{service.description_4} </p>
              </div>
            </div>
            <div className="w-full mt-24">
              <div className="mainTitle ">
                <h2> {service.title_5}</h2>
              </div>
              <div className="description">
                <p className="mt-2 text-xl leading-10 text-gray-900  ">{service.description_5} </p>
              </div>
            </div>


          </div>
          <Plans />
          <Testimonials />

          <div className="subService mt-24">
            <Title className="blueColor" subTitle={t('Check')} title={t('OtherService')} />
            <div className="row flex items-center justify-center flex-wrap mt-16">
              {related_services}
            </div>
          </div>

        </div>

      </div>
    </Layout >
  )
}

export default ServiceItem