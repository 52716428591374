import { useEffect } from 'react'
import Layout from '../Components/Layout' 
import Plans from '../Components/Plans/Plans' 
import { useTranslation } from "react-i18next"
 
const Packages = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = t('packege_page')
  }, [t]);
  return (
    <Layout>
      {/* <PageHeader image={packages} title={t('Packages')} /> */}
      <section id="ourPackage" className='md:pt-80'>
        <Plans /> 
      </section>
    </Layout>

  )
}

export default Packages