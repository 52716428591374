import Fav from '../../../img/Logo.gif'
import './Loader.scss'
const Loader = () => {

          return (
                    // <div className='fixed h-screen w-screen top-0 left-0 z-50 bg-black text-black text-9xl flex justify-center items-center'>
                    //           <img src={Fav} alt="" className='w-52' />
                    // </div>
                    <></>
          )
}

export default Loader