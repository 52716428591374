import React from 'react'

const Title = (props) => {
     return (
          <div className={props.class}>
               <div className="subTitle mb-4">
                    <p className='text-3xl flex items-center '>
                         <span className=' relative flex mr-4 overflow-hidden h-1 w-40'>.</span>
                         {props.subTitle}
                    </p>
               </div>
               <div className={`mainTitle flex items-center justify-start flex-wrap ${props.titlecClassName}`}>
                    <h1> {props.title}{props.small && <small className='mb-12 mx-6 inline-flex text-[2rem]'> {props.small}</small>}</h1>
               </div>
               <div className="description ">
                    <p>{props.description}</p>
               </div>
          </div>
     )
}

export default Title