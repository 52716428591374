import React from 'react'
import SocialMedia from './Components/SocialMedia/SocialMedia'
import MobileNav from './Components/Layout/MobileNav'
import { Home, About, Services, Contact, Portfolio, Packages, Error, ServiceItem, Project, PrivacyPolicy } from './Pages'
import { Route, BrowserRouter, Routes } from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import ThanksPage from './Pages/Thanks';

function App() {

  return (
    <>
      <SocialMedia />
      <BrowserRouter>
        {window.innerWidth < 768 ? <MobileNav /> : ''}
        <ScrollToTop />
        <Routes>
          <Route path='/web-design' element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/packages' element={<Packages />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/portfolios' element={<Portfolio />} />
          <Route path='/project/:id' element={<Project />} />
          <Route path="*" element={<Error />} />
          <Route path='/services/:id/:slug' element={<ServiceItem />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/thanks' element={<ThanksPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
