import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import Title from '../UI/Title/Title'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quotes from '../../img/quotes.jpg'
import './Testimonials.scss'
import { useState, useEffect } from 'react'
import { Settings, config, url } from '../../Components/Utils/Common'
import axios from 'axios'
import { t } from 'i18next';
// portfolio
const Testimonials = () => {
          const [testimonial, setTestimonials] = useState({})
          useEffect(() => {
                    axios.get(url + 'testimonials', config).then(response => {
                              const data = (response.data.data)
                              setTestimonials(data)
                              // console.log(data)
                    }).catch(error => {
                              console.disableYellowBox = true;
                    })
          }, []);

          let testimonialUsers = <p>No Testimonials </p>
          if (testimonial.length > 0) {
                    testimonialUsers = testimonial.map((item, index) => (
                              <div className='p-4 pb-20' key={index}>
                                        <div className="item text-center p-4  pb-16 relative">
                                                  <div className="quots text-8xl">
                                                            <img src={quotes} alt="" className='mx-auto' />
                                                  </div>
                                                  <div className="blueColor text-2xl my-4">
                                                            <p className='leading-10 text-overflow lines three h-40'> {item.comment} </p>
                                                  </div>
                                                  <div className="stars flex items-center justify-center mb-4 mx-2 text-yellow-400">
                                                            {item.rating == 0 ? <><AiOutlineStar /><AiOutlineStar /><AiOutlineStar /><AiOutlineStar /><AiOutlineStar /></> : ''}
                                                            {item.rating == 1 ? <><AiFillStar /><AiOutlineStar /><AiOutlineStar /><AiOutlineStar /><AiOutlineStar /></> : ''}
                                                            {item.rating == 2 ? <><AiFillStar /><AiFillStar /><AiOutlineStar /><AiOutlineStar /><AiOutlineStar /></> : ''}
                                                            {item.rating == 3 ? <><AiFillStar /><AiFillStar /><AiFillStar /><AiOutlineStar /><AiOutlineStar /></> : ''}
                                                            {item.rating == 4 ? <><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiOutlineStar /></> : ''}
                                                            {item.rating == 5 ? <><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></> : ''}
                                                  </div>
                                                  <div className="imgAvatar w-28 h-28 mx-auto absolute ">
                                                            <img src={item.icon} alt="" />
                                                  </div>
                                                  <q className="text-xl italic text-overflow block  text-right absolute text-gray-400 bottom-2 top-auto right-4">{item.name} </q>
                                                  <a target='_blank' rel="noreferrer" href='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zDDMMjOpSrM0YLRSNagwTjVNM7NMMUtKTUqzNDJJszKoSLa0sDQ0MjNINDNKTUk1NfDiTipKzMxTSM7JTM4GAHdNE2g&q=brain+click&oq=brainclickads.com&aqs=chrome.2.69i58j69i57j46i13i175i199j69i60l5.4325j0j1&sourceid=chrome&ie=UTF-8#lrd=0x3e5f69d6bebf924f:0xc9891260a62ede50,1,,,' className="text-xl italic text-overflow block  text-right absolute text-gray-400 bottom-2 top-auto left-4">by google </a>

                                        </div>
                              </div >
                    ))
          }



          return (
                    <section id="Testimonials">
                    
                              <div className="container mx-auto">
                                        <div className="row flex items-center justify-start flex-wrap">
                                                  <div className="w-full lg:w-1/2 md:py-4 md:px-32 md:pl-0 mb-8 md:mb-0">
                                                            <Title className="blueColor" subTitle={t('Testimonials')} title={t('ClientSay')}  />
                                                  </div>
                                                  <div className="w-full md:py-4 md:px-32 md:pl-0 mb-8 md:mb-0">
                                                            <Slider {...Settings}>
                                                                      {testimonialUsers}
                                                            </Slider>
                                                  </div>
                                        </div>
                              </div>
                    </section>
          )
}

export default Testimonials