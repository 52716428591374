import Title from '../UI/Title/Title'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from 'react'
import {  config, url } from '../../Components/Utils/Common'
import axios from 'axios'
import { t } from 'i18next';
const Settings = {
          dots: false,
          // infinite: false,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: true,
          initialSlide: 0,
          centerPadding: "60px",
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true, 
          responsive: [
                    {
                              breakpoint: 1800,
                              settings: {
                                        slidesToShow: 5,
                                        slidesToScroll: 1,
                                        infinite: true,
                              }
                    },
                    {
                              breakpoint: 1023,
                              settings: {
                                        slidesToShow: 4,
                                        slidesToScroll: 1,
                                        initialSlide: 1
                              }
                    },
                    {
                              breakpoint: 768,
                              settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1
                              }
                    } 
          ]
};
const Partners = () => {
          const [logos, setLogos] = useState({})
        
          useEffect(() => {
                    axios.get(url + 'logos', config).then(response => {
                              const data = (response.data.data)
                              setLogos(data)
                              // console.log(data)
                    }).catch(error => {
                              console.log(error)
                    })
          }, []);

          let PartnersLogos = <p>No Testimonials </p>
          if (logos.length > 0) {
                    PartnersLogos = logos.map((item, index) => (
                              <a href={item.url} target='_blank' rel="noreferrer" key={index} className="mx-auto my-12 ">
                                        <img src={item.image} alt="" className='h-32 w-auto max-w-full mx-auto px-2 sm:px-4 lg:px-8 object-contain object-center' />
                              </a>

                    ))
          }



          return (
                    <section id="Partners">

                              <div className="container mx-auto">
                                        <div className="row flex items-center justify-start flex-wrap">
                                                  <div className="w-full lg:w-1/2 md:py-4 md:px-32 md:pl-0 mb-8 md:mb-0">
                                                            <Title className="blueColor" subTitle={t('Partners')} description={t('bunch')} />
                                                  </div>
                                                  <div className="w-full md:py-4 md:px-32 md:pl-0 mb-8 md:mb-0">
                                                            <Slider {...Settings}>
                                                                      {PartnersLogos}
                                                            </Slider>
                                                  </div>
                                        </div>
                              </div>
                    </section>
          )
}


export default Partners