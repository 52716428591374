import Layout from '../Components/Layout'
import { config, url } from '../Components/Utils/Common';
import { useEffect, useState } from 'react';
import axios from 'axios';

const Project = () => {
     const projectId = window.location.pathname.split('/')[2]
     const [projectMockup, setProjectMockup] = useState({});

     useEffect(() => {
          axios(url + `portfolio/details/${projectId}`, config).then(response => {
               const data = response.data.data
               setProjectMockup(data)
               console.log(data)

          }).catch(error => {
               console.log(error)
          })
     }, [projectId]);


     return (
          <Layout>
               <div className="min-h-screen pt-8 md:pt-56 flex items-start justify-center ">
                    <img src={projectMockup.mockups} alt="" className='w-full mx-auto' />
               </div>
          </Layout >
     )
}

export default Project