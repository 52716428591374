import './Statistics.scss'
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import { useState, useEffect } from 'react'
import { config, url } from '../../Components/Utils/Common'
import axios from 'axios'
import Fav from '../../img/Logo.gif'
import { useTranslation } from "react-i18next"

const Statistics = () => {
    const { t } = useTranslation()
  const [IconItem, setIconItem] = useState({})
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios.get(url + 'stats', config).then(response => {
      if (response.status === 404) {
        setIsNotFound(true);
        return;
      }

      if (response.status === 400) {
        setIsNotValid(true);
        return;
      }

      const data = (response.data.data)
      setIconItem(data)
      setIsLoading(false);
    }).catch(error => {
      console.log(error)
    })
  }, []);

  if (isNotFound) {
    return (
      <>
        <p> {t('we_dont_have')} </p> 
      </>
    );
  }

  if (isNotValid) {
    return (
      <>
        <p className="error">Sorry! The reservation ID is invalid.</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <div className='min-h-full h-96   text-white text-9xl flex justify-center items-center'>
        <img src={Fav} alt="" className='w-52' />
      </div>
    )
  }



  let icons = <p> {t('we_dont_have')} </p> 
  if (IconItem.length > 0) {

    icons = IconItem.map((item, index) => (
      <div className="w-1/2 md:w-1/4" key={index}>
        <div className="item text-white  py-24">
          <div className="iconFont text-center">
            {/* <AiOutlineFundProjectionScreen className='mx-auto text-8xl' /> */}
            <img src={item.icon} alt={item.name} className='mx-auto w-36 invert' />
          </div>
          <CountUp end={parseInt(item.value)} redraw={true}> {/* 9000 */}
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <div className="number text-6xl font-black">
                  <h1 className='my-8' ref={countUpRef}>.</h1>
                </div>
              </VisibilitySensor>
            )}
          </CountUp>
          <div className="title">
            <p className='text-white text-3xl '>{item.name} </p>
          </div>
        </div>
      </div>
    ))
  }


  return (
    <section id="Statistics" className=''>
      <div className="container mx-auto my-24">
        <div className="row flex items-center justify-center flex-wrap text-center">
          {icons}
        </div>
      </div>
    </section>
  )
}

export default Statistics