import FAQ from './FAQ'
import Form from '../Form/Form'
import './Frequently.scss'
import Title from '../UI/Title/Title'
import { useState, useEffect } from 'react'
import axios from 'axios';
import { config, url } from '../Utils/Common'
import { t } from 'i18next'

const Frequently = () => {
     const [faq, setFaq] = useState({})
     useEffect(() => {
          axios.get(url + 'faq', config).then(response => {
               const data = (response.data.data)
               setFaq(data)
          }).catch(error => {
               console.disableYellowBox = true;
          })
     }, []);

     let faqItem = <p> {t('we_dont_have')} </p>
     if (faq.length > 0) {
          faqItem = faq.map((item, index) => (
               <FAQ key={index} title={item.question} description={item.answer} />
          ))
     }

     return (
          <section id='FAQ'>
               <div className="container mx-auto my-12 mt-24">
                    <div className="row flex items-start  justify-center flex-wrap">
                         <div className="w-full md:w-1/2 mb-8 md:mb-0">
                              <div className="w-full pr-0 sm:pr-8 xl:w-8/12">
                                   <div className="header px-4 py-16 ">
                                        <Title className='blueColor' subTitle={t('Frequently Questions')} title={t('HOW CAN WE HELP YOU?')} />
                                   </div>
                                   <Form />
                              </div>
                         </div>
                         <div className="w-full md:w-1/2 mt-12 md:mt-0">
                              <div className="subTitle mb-4">
                                   <p className='text-3xl flex items-center blueColor'>
                                        <span className=' relative flex mr-4 overflow-hidden h-1 w-40'>.</span>
                                        {t('Frequently Questions')}
                                   </p>
                              </div>
                              <div className="mainTitle blueColor">
                                   <h2>   {t('feel')}</h2>
                              </div>
                              <div className="items mt-12  ">
                                   {faqItem}
                              </div>


                         </div>
                    </div>
               </div>
          </section>
     )
}

export default Frequently