// import Header from './Header'
import Footer from './Footer'
import ScreenNav from './ScreenNav'
import './Header.scss'

function index(props) {
  return (
    <>
      {window.innerWidth > 768 && <ScreenNav/> }
      {props.children}

      <Footer />
    </>
  )
}

export default index 