import Card from './Card'
import Modal from '../Modal/Modal'
import Form from '../Form/Form'
import './Strategies.scss'
import Title from '../UI/Title/Title'
import { useTranslation } from "react-i18next"
import { useState } from 'react'
const Strategies = () => {
     const [IsOpen, setIsOpen] = useState(false)

     const { t } = useTranslation()
     return (
          <>
               <div>
                    <Modal open={IsOpen} onClose={() => setIsOpen(false)} >
                         <Form />
                    </Modal>
               </div>
               <div id="Strategies" className='py-16'>
                    <div className="container mx-auto">
                         <div className="row flex items-center justify-center flex-wrap">
                              <div className="text-white w-full lg:w-1/2 md:py-4 md:px-32 md:pl-0 mb-8 md:mb-0">
                                   <Title className='text-white' subTitle={t('6')} title={t('5')} description={t('An assembly of creative visionaries coming together to establish a comprehensive branding and ideas agency.')} />
                              </div>
                              <div className="w-full lg:w-1/2 ">
                                   <div className="row StrategyCard flex items-center justify-center flex-wrap">
                                        <Card title={t('7')} link={true} subTitle={t('BrandStrategyArt')} description={t('Our approach involves thorough research of your target market, enabling us to craft highly efficient strategies aimed at effectively reaching your customers.')} button={t('Live_Chat')} href={"tel:+971 52 996 2679"} />
                                        <Card title={t('77')} subTitle={t('77')} description={t('888')} button={t('Get_Free_Quote')} action={() => setIsOpen(true)} />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div></>
     )
}

export default Strategies