import { useState, useEffect } from 'react'
import { config, url } from '../Utils/Common'
import axios from 'axios';
import PortfolioCard from '../Tabs/AllTabs/PortfolioCard'
import { useTranslation } from "react-i18next"
// import Lottie from 'react-lottie';
// import noItems from '../Lottiefiles/noItems.json';
import './Portfolios.scss'
import Title from '../UI/Title/Title';
import { BsJustify } from 'react-icons/bs';

const Portfolios = () => {
     const { t } = useTranslation()
     const [activeTab, setActiveTab] = useState('web');
     const [showFirstITem, setShowFirstITem] = useState(true);
     const [showButton, setShowButton] = useState(false);
     const [showButtonLess, setShowButtonLess] = useState(false);
     const [showButtonMore, setShowButtonMore] = useState(false);
     const [isLoading, setIsLoading] = useState(false);
     const [isError, setError] = useState(false);

     const [tab, setTabs] = useState([])
     const [pageNumber, setPageNumber] = useState(8)

     const [portfolio, setPortfolio] = useState([])

     useEffect(() => {
          if (activeTab === 'web') {
               setIsLoading(true)

          }
          axios(url + 'portfolio/types', config).then(response => {
               const data = (response.data.data)
               setTabs(data)
               console.log(data)
               setTimeout(() => {
                    setIsLoading(false)
               }, 1000);
          }).catch(error => {
               console.log(error)
               setIsLoading(false)
          })
     }, []);


     useEffect(() => {
          axios(url + `portfolio/types/${activeTab}`, config).then(response => {
               const data = (response.data.data)
               setPortfolio(data)

               if (data.length > 8) {
                    setShowButton(true)
                    setShowButtonMore(true)
               } else {
                    setShowButton(false)
                    setShowButtonMore(false)
               }
               if (pageNumber == 8) {
                    setShowButtonLess(false)
               }
               if (pageNumber >= data.length) {
                    setShowButtonMore(false)
                    setShowButtonLess(true)
               } else {
                    setShowButtonMore(true)
               }
          }).catch(error => {
               setError(true)
               console.log(error)
          })
     }, [activeTab, pageNumber]);



     const handleTap = (item) => {
          setShowFirstITem(false)
          setIsLoading(true)
          setActiveTab(item);
          setTimeout(() => {
               setIsLoading(false)
          }, 1000);
     };


     const appendData = () => {
          setPageNumber(pageNumber + 8)
     }
     const lessData = () => {
          if (pageNumber > 8) {
               setPageNumber(pageNumber - 8)
          }
     }



     return (
          <div className="Tabs portfolio">
               <div className="container mx-auto">
                    <div className="mt-16 mb-6">
                         <Title titlecClassName={'justify-center'} class="text-center" className="blueColor" title={t('Our Portfolio')} description='' />
                    </div>
                    <ul className="nav flex items-center justify-center flex-wrap mt-8">
                         {
                              tab.map((item, index) => (
                                   <button onClick={() => { handleTap(item.type); setPageNumber(8) }} key={index} className={"btn mx-4 my-2 cursor-pointer " + (activeTab == item.type ? "active btn " : "lineBtn")} >  {item.name} </button>
                              ))
                         }
                    </ul>
               </div>
               {isError ?
                    <div className='min-h-[40rem] flex items-center justify-center font-bold text-[1.9rem]'>
                         <h3>
                              {t('There is something wrong')}
                         </h3>
                    </div>
                    :
                    <div className="outlet font-black relative overflow-hidden min-h-[50rem]">
                         {isLoading ?
                              <div className="absolute  min-h-[50rem] bg-white z-10 w-full left-0 flex items-center justify-center">
                                   {t('please wait')}
                              </div>
                              :
                              <div className="container mx-auto">
                                   <div id="portfolioTabs" className='singleTab mt-12 text-center relative overflow-hidden'>


                                        {
                                             portfolio.length === 0 && !isLoading ?
                                                  <>
                                                       {
                                                            setTimeout(() => {
                                                                 <div className="row flex items-center justify-center flex-col">
                                                                      <h3 className='text-cyan-600 text-4xl'> {t('no items')}  </h3>
                                                                 </div>
                                                            }, 5000)
                                                       }

                                                  </>
                                                  :
                                                  <div className="row flex items-center justify-center flex-wrap">


                                                       {
                                                            portfolio.slice(0, pageNumber).map((item, index) => (
                                                                 <PortfolioCard
                                                                      class={item.show_type == 'mobile' ? 'w-full lg:w-1/2 xl:w-1/3 ' : 'w-full sm:w-1/2 md:w-1/3 xl:w-1/4 '}
                                                                      id={item.id}
                                                                      key={index}
                                                                      image={item.icon}
                                                                      url={item.url}
                                                                      type={item.show_type}
                                                                      description={item.description}
                                                                      url_android={item.url_android}
                                                                      image_android={item.image_android}
                                                                      url_ios={item.url_ios}
                                                                      isYoutube={item.youtube_link && true}
                                                                      video_link={item.video_link}
                                                                      youtube_link={item.youtube_link}
                                                                      name={item.name}
                                                                      booster={item.booster}
                                                                      image_ios={item.image_ios} />
                                                            ))
                                                       }
                                                       {
                                                            showButton &&
                                                            <div className="w-full">
                                                                 {showButtonLess && <button className="btn m-4 inline-block text-white font-bold mt-12" onClick={lessData}> {t('show_less')} </button>}
                                                                 {showButtonMore && <button className="btn m-4 inline-block text-white font-bold mt-12" onClick={appendData}> {t('View_Our_Portfolio')} </button>}
                                                            </div>
                                                       }
                                                  </div>
                                        }

                                   </div>
                              </div>

                         }
                    </div>
               }
          </div>
     )
}

export default Portfolios