
import { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import uae from '../../../img/uae.png'
import en from '../../../img/en.png'

const languages = [

          {
                    img: en,
                    code: 'en',
                    name: 'English',
                    country_code: 'gb',
          },
          {
                    img: uae,
                    code: 'ar',
                    name: 'العربية',
                    dir: 'rtl',
                    country_code: 'sa',
                    lang: 'ar',
          },
]

const Lang = () => {
          const { t } = useTranslation()
          const currentLanguageCode = localStorage.getItem('i18nextLng') || 'en'
          const currentLanguage = languages.find(l => l.code === currentLanguageCode)
          useEffect(() => {
                    if (localStorage.i18nextLng == 'ar' || localStorage.i18nextLng == 'en') {
                              console.log(localStorage.i18nextLng)
                              document.documentElement.dir = currentLanguage.dir || 'ltr'
                              document.documentElement.lang = i18next.language;
                    } else {
                              localStorage.setItem("i18nextLng", 'ar');
                              document.documentElement.lang = 'ar';
                    }
                    // document.title = t('app_title')
          }, [currentLanguage, t])


          return (
                    <>
                              {
                                        languages.map(({ code, name, country_code, img }) => (
                                                  <div key={country_code}>
                                                            {

                                                            }
                                                            <button className='text-white ltr:mr-0 ltr:ml-auto rtl:ml-0 rtl:mr-auto langButton flex items-center'
                                                                      onClick={() => { window.location.reload(true); setTimeout(i18next.changeLanguage(code), 1000); }}
                                                                      style={{ display: code === currentLanguageCode ? 'none' : 'flex' }} disabled={code === currentLanguageCode}>
                                                                      <img src={img} alt="" className={`w-12 mr-4 ${code}`} />
                                                                      {/* <p className='text-gray-700 my-0'>{name}</p> */}
                                                            </button>
                                                  </div>
                                        ))
                              }
                    </>
          )
}

export default Lang